import { useState } from 'react';
import { CloseIcon } from '@icons/Close';
import { InfoIcon } from '@icons/InfoIcon';
import { Text, Title } from '../../Typography/index';
import { NutriscoreImage } from './images';
import {
  Close,
  Container,
  Content,
  LearnMore,
  Popup,
  PopupBody,
  PopupHead,
  Shadow,
} from './styles';

export interface IProductNutriScoreProps {
  data: {
    nutriScore: string;
    title: string;
    details: string;
    learnMore: string;
  };
}

export const ProductNutriScore = ({ data }: IProductNutriScoreProps) => {
  const [showPopup, setShowPopup] = useState(false);
  return (
    <>
      {showPopup ? (
        <>
          <Shadow onClick={() => setShowPopup(false)} />
          <Popup>
            <PopupHead>
              <Close onClick={() => setShowPopup(false)}>
                <CloseIcon size={24} />
              </Close>
            </PopupHead>
            <PopupBody>
              <Title tag="div" type="md">
                {data.title}
              </Title>
              <Content dangerouslySetInnerHTML={{ __html: data?.details }} />
            </PopupBody>
          </Popup>
        </>
      ) : null}
      <Container>
        <NutriscoreImage nutriScore={data.nutriScore} />
        <LearnMore onClick={() => setShowPopup(true)}>
          <Text tag="span" type="lead">
            {data.learnMore}
          </Text>
          <InfoIcon />
        </LearnMore>
      </Container>
    </>
  );
};
