export default [
  {
    name: 'Twitter',
    shareMsg: 'Tweet it',
    socialMediaComponentName: 'TwitterShareButton',
    socialSharePosition: ['SocialShareComponent'],
    assetsComponents: [
      {
        asset: [
          {
            url: 'https://media.graphcms.com/PzcyJyNOTu2aoan13zXX',
          },
        ],
      },
    ],
  },
  {
    name: 'facebook',
    shareMsg: 'Share it',
    socialMediaComponentName: 'FacebookShareButton',
    socialSharePosition: ['SocialShareComponent'],
    assetsComponents: [
      {
        asset: [
          {
            url: 'https://media.graphcms.com/gmH97uIpT5gtgiLR0JAJ',
          },
        ],
      },
    ],
  },
  {
    name: 'Whatsapp',
    shareMsg: 'Share it',
    socialMediaComponentName: 'WhatsappShareButton',
    socialSharePosition: ['SocialShareComponent'],
    assetsComponents: [
      {
        asset: [
          {
            url: 'https://media.graphcms.com/x3TdoRduRGOvc9zEoytu',
          },
        ],
      },
    ],
  },
  {
    name: 'pinterest',
    shareMsg: 'Pin it',
    socialMediaComponentName: 'PinterestShareButton',
    socialSharePosition: ['Footer', 'SocialShareComponent'],
    assetsComponents: [
      {
        asset: [
          {
            url: 'https://media.graphcms.com/3oNYf3rSqmvL8ihawtbT',
          },
        ],
      },
    ],
  },
];
