//@ts-nocheck
import { customImageLoader } from 'components/NextImage';
import PictureA from './A.jpeg';
import PictureB from './B.jpeg';
import PictureC from './C.jpeg';
import PictureD from './D.jpeg';
import PictureE from './E.jpeg';
import Image from 'next/image';

export const NutriscoreImage = ({ nutriScore }) => {
  const imagesMap = {
    A: PictureA,
    B: PictureB,
    C: PictureC,
    D: PictureD,
    E: PictureE,
  };
  const Picture = (imagesMap as any)[nutriScore];
  return <Image src={Picture} width={153} height={91} alt="" loader={customImageLoader} />;
};
