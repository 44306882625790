import clsx from 'clsx';
import NextImage from 'components/NextImage';
import { Text } from 'components/Typography';
import { FC } from 'react';
import { DictionaryItem } from 'service/dictionary/dictionaryService';

export type TProductLabels = {
  identifier: string;
  imageUrl: string;
  translations: DictionaryItem;
}[];

type Props = {
  labels: TProductLabels;
  showDescriptionText?: boolean;
  isCentered?: boolean;
};

export const ProductLabels: FC<Props> = ({ labels, showDescriptionText, isCentered }) => {
  const listStyles = clsx(
    'flex flex-wrap mt-4 mb-4 md:justify-start',
    isCentered ? 'justify-center' : 'justify-start',
  );

  return (
    <ul className={listStyles}>
      {labels.map(({ identifier, imageUrl, translations }) => (
        <li
          className={`flex ${
            showDescriptionText ? 'w-[144px]' : 'w-[80px]'
          } flex-col mb-2 mt-2 mr-4`}
        >
          <div className="w-[100%] h-[80px] border-1 rounded border-beige mb-1">
            <span className="relative block w-[75px] h-[75px] mx-auto">
              <NextImage src={imageUrl} alt={`${identifier}-logo`} />
            </span>
          </div>

          {showDescriptionText && (
            <Text type="sm" tag="p">
              {translations.value}
            </Text>
          )}
        </li>
      ))}
    </ul>
  );
};
