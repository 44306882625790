import tw from 'tailwind-styled-components';
import styled from 'styled-components';
import Picture from '../../NextImage';

export const ProductVisualWrapper = tw.div`
  relative
  flex
  justify-center
  items-center
  md:min-h-528
`;

export const ProductWrapper = styled.div`
  position: relative;
  top: 80px;
  width: 280px;
  height: auto;
  max-width: 70%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 3;

  @media (min-width: 768px) {
    left: 35px;
    top: 20%;
    width: 87%;
    height: 400px;
    max-width: initial;
  }

  @media (max-width: 1024px) {
    top: 100px;
    left: 0;
  }

  @media (min-width: 1024px) {
    width: 415px
    height: 415px
  }

  img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: auto;
    margin: 0 auto;
    object-fit: contain;
  }
`;

export const PictureAsset = styled(Picture as any)`
  width: 100%;
  height: 100%;
  max-height: 46dvh;
`;

export const ProductVisual = styled.div`
  @media (max-width: 767px) {
    padding-left: 0;
    padding-top: 4rem;
  }

  @media (max-width: 561px) {
    padding-top: 0;
  }
`;

export const BlobSvg = styled.svg`
  position: absolute;
  width: 100%;
  top: -40px;

  @media (min-width: 768px) {
    top: 20px;
  }

  @media (min-width: 1024px) {
    top: 40px;
  }

  @media (max-width: 382px) {
    top: -60px;
  }
`;
