import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #f7e9d5;
  border-radius: 4px;
  padding: 0 15px;
  height: 100px;
  margin: 20px 6%;
  @media (min-width: 768px) {
    margin: 20px 3%;
    min-width: 376px;
    display: inline-flex;
  }
  @media (min-width: 1600px) {
    margin: 20px 4%;
  }

  @media (min-width: 1920px) {
    margin: 20px 6%;
  }
`;
export const LearnMore = styled.div`
  display: flex;
  align-items: center;
  margin-left: 25px;
  cursor: pointer;
  svg {
    margin-left: 12px;
  }
`;
export const Shadow = styled.div`
  position: fixed;
  background: #2d2a29;
  opacity: 0.6;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 51;
`;
export const Popup = styled.div`
  position: fixed;
  background: #fff;
  border-radius: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 90vh;
  max-height: 600px;
  width: 90vw;
  max-width: 628px;
  z-index: 52;
  display: flex;
  flex-direction: column;
`;
export const PopupHead = styled.div`
  height: 48px;
  border-bottom: 1px solid #f7e9d5;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
export const Close = styled.div`
  margin-right: 12px;
  cursor: pointer;
`;
export const PopupBody = styled.div`
  padding: 0 16px;
  flex: 1;
  overflow-y: auto;
  @media (min-width: 768px) {
    padding: 0 80px;
  }
  
  > div {
    margin: 24px; 0 16px;
  }
`;
export const Content = styled.div`
  p {
    margin-bottom: 16px;
  }
`;
