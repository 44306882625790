import { useState, useRef, useEffect } from 'react';
import { desktopAnimationTriggerPoints, mobileAnimationTriggerPoints } from './animationConfig';
import {
  BannerWrapper,
  BannerInner,
  BannerContentLayer,
  BannerPackagingLayer,
  ContentWrapper,
  PanelCurve,
  ContentInner,
} from './styles';

import type { IProductHeroBannerProps, AnimationConfig } from './types';
import { ProductPackagingVisual } from './ProductPackagingVisual';
import { ProductHeroDetails, ProductPreparation, ProductTip } from './ProductHeroDetails';
import { ProductNutriScore } from './ProductNutriScore';

const TopCurve = (
  <PanelCurve top>
    <svg viewBox="0 0 320 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m 320,0 v 22.8436 c 0,0 -73.556,-19.843576 -160.667,-19.843584 C 72.2222,3.000009 3.77286e-6,22.8436 3.77286e-6,22.8436 L 0,0 Z"
        fill="#ffffff"
      />
    </svg>
  </PanelCurve>
);

const BottomCurve = (
  <PanelCurve top={false}>
    <svg viewBox="0 0 320 22.677164" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M 0,22.677165 V 0 C 0,0 73.5557,19.8436 160.667,19.8436 247.778,19.8436 320,0 320,0 v 22.677165 z"
        fill="#ffffff"
      />
    </svg>
  </PanelCurve>
);

export const ProductHeroBannerSimple = ({
  id = 'prod-hero',
  productName,
  productImage,
  productTags,
  fillingQuantity,
  productDescription,
  preparationInstruction,
  cookingTip,
  buyNowLink,
  labels,
  productOnly = false,
  searchLink,
  socialMediaListComponents,
  trackingEvents,
  locale,
  productGtin,
  subBrandLogo,
  nutriScore,
  productLabels,
  seeAllProductLabelsTitle,
}: IProductHeroBannerProps) => {
  const bannerRef = useRef<HTMLDivElement>(null);

  const [animationConfig, setAnimationConfig] = useState<AnimationConfig>(
    mobileAnimationTriggerPoints,
  );

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const viewAnimationConfig =
      window.innerWidth >= 768 ? desktopAnimationTriggerPoints : mobileAnimationTriggerPoints;
    setAnimationConfig(viewAnimationConfig);
  }, []);

  const productVisual = (
    <ProductPackagingVisual id={`${id}-packaging`} productImage={productImage} />
  );

  const bannerDetails = (
    <ProductHeroDetails
      id={`${id}-details`}
      productName={productName}
      productTags={productTags}
      productImage={productImage}
      productLabels={productLabels}
      seeAllProductLabelsTitle={seeAllProductLabelsTitle}
      fillingQuantity={fillingQuantity}
      productDescription={productDescription}
      buyNowLink={buyNowLink}
      labels={labels}
      preparationInstruction={preparationInstruction}
      cookingTip={cookingTip}
      animationConfig={animationConfig}
      displayTarget={animationConfig.target}
      searchLink={searchLink}
      socialMediaListComponents={socialMediaListComponents}
      trackingEvents={
        trackingEvents?.clickSocialShare && {
          clickSocialShare: trackingEvents?.clickSocialShare,
          clickBuyNow: trackingEvents?.clickBuyNow,
        }
      }
      locale={locale}
      productGtin={productGtin}
      subBrandLogo={subBrandLogo}
    />
  );

  return (
    <>
      <BannerWrapper data-test={id} ref={bannerRef}>
        <BannerInner className="flex flex-col-reverse md:flex-row">
          <BannerContentLayer $productOnly={productOnly} data-test={`${id}-details-layer`}>
            {bannerDetails}
          </BannerContentLayer>
          <BannerPackagingLayer data-test={`${id}-packaging-layer`}>
            {productVisual}
          </BannerPackagingLayer>
        </BannerInner>
      </BannerWrapper>
      {nutriScore?.nutriScore ? <ProductNutriScore data={nutriScore} /> : null}
      {preparationInstruction || cookingTip?.length ? (
        <ContentWrapper>
          {TopCurve}
          <ContentInner>
            {preparationInstruction && (
              <ProductPreparation
                productName={productName}
                id={`${id}-prep`}
                preparationInstruction={preparationInstruction}
                labels={labels}
                displayTarget={animationConfig.target}
              />
            )}
            {cookingTip?.length ? (
              <ProductTip
                id={`${id}-tip`}
                productName={productName}
                cookingTip={cookingTip}
                labels={labels}
                displayTarget={animationConfig.target}
              />
            ) : null}
          </ContentInner>
          {BottomCurve}
        </ContentWrapper>
      ) : null}
    </>
  );
};
