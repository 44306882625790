import tw from 'tailwind-styled-components';
import styled from 'styled-components';

export const BannerWrapper = styled.div`
  overflow-y: visible;
  overflow-x: clip;
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  @media (min-width: 480px) {
    overflow: visible;
    overflow-x: clip;
  }
`;

export const BannerInner = tw.div`
  flex
  flex-col-reverse
  md:flex-row
`;

export const BannerContentLayer = tw.div<{ $productOnly?: boolean }>`
  relative
  flex-1
`;

export const BannerPackagingLayer = tw.div`
  flex-1
  relative
  pt-10
`;

export const ContentWrapper = styled.div`
  background-color: #fdf9f4;
`;

export const PanelCurve = styled.div<{ top: boolean }>`
  display: block;
  width: 100%;
  margin: ${({ top = false }) => (top ? '0 0 -1px' : '-1px 0 0')};

  svg {
    width: 100%;
  }
`;

export const ContentInner = tw.div`
  flex
  flex-col
  md:flex-row
`;
