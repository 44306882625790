import tw from 'tailwind-styled-components';
import styled from 'styled-components';
import { Title, Text as Paragraph } from '../../Typography';

export const DetailsPanel = styled.div`
  text-align: center;

  @media (min-width: 768px) {
    margin: 0 6%;
    text-align: left;
  }
  @media (min-width: 1600px) {
    margin: 0 8%;
  }

  @media (min-width: 1920px) {
    margin: 0 12%;
  }
`;
export const DetailsPanelContent = styled.div<{
  $mobilePadding?: boolean;
}>`
  padding: ${({ $mobilePadding = false }) => ($mobilePadding ? '2rem 6%' : '10rem 6%')};
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  @media (min-width: 768px) {
    background: transparent;
    padding: 0;
    text-align: left;
    align-items: flex-start;
  }
  @media (max-width: 767px) {
    animation: none;
  }
`;

export const DetailsBranding = tw.div`
  my-5
  mt-16
  md:mt-20
`;

export const BannerContent = styled.div`
  position: relative;
`;

export const BannerContentDetails = styled(BannerContent)`
  margin-top: 35px;
  @media (max-width: 767px) {
    top: 0 !important;
    position: relative;
    width: 100%;
    margin-top: 0;
  }
`;

export const SubBrandLogo = styled.img`
  margin: 20px auto;
  max-width: 170px;
  @media (min-width: 767px) {
    margin: 0 0 20px;
  }
`;

export const DetailsTitle = tw(Title)`
  mb-5
`;

export const CertificationsList = tw.div`
  flex
  flex-wrap
  justify-center
  items-center
  mt-10
  mb-10
  gap-x-4
  gap-y-4
`;

export const CertificationContainer = tw.div`
  w-90
  h-90
  relative
  border
  border-beige
  flex
  justify-center
  items-center
  md:w-60
  md:h-60
`;

export const SeeAllProductLabelsTitle = tw.a`
  uppercase
  text-sm
  mb-5
  text-darkgray
`;

export const ProductTagList = tw.div`
  mt-5
`;

export const FillingQuantity = tw(Paragraph)`
  mb-2.5
  md:mb-6
  uppercase
`;

export const BoldString = tw.span`
  font-bold
`;

export const BannerActions = tw.div`
  flex
  items-center
  md:items-start
  sm:justify-start
  flex-col
  md:flex-row
  mt-7
  w-full
  justify-center
  `;

export const BuyButtonWrapper = tw.div`
  cursor-pointer
  mt-cta
  -mb-5
  md:-mb-8
`;

export const ProductPreparationContainer = styled.div`
  @media (min-width: 768px) {
    margin: 50px 6% 0;
    text-align: left;
  }
  @media (min-width: 1600px) {
    margin: 50px 8% 0;
  }

  @media (min-width: 1920px) {
    margin: 50px 12% 0;
  }
`;

export const ProductTipContainer = styled.div`
  margin: 50px 6% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) {
    align-items: start;
  }
  @media (min-width: 1600px) {
    margin: 50px 8% 0;
  }
  @media (min-width: 1920px) {
    margin: 50px 12% 0;
  }
`;
