import type { IAssetImage } from '../types';
import { ImagesService } from 'service/images/imagesService';

import {
  ProductVisualWrapper,
  ProductWrapper,
  PictureAsset,
  ProductVisual,
  BlobSvg,
} from './styles';

export interface IProductPackagingVisualProps {
  id: string;
  productImage?: IAssetImage;
}

export const ProductPackagingVisual = ({ id, productImage }: IProductPackagingVisualProps) => (
  <ProductVisual>
    <ProductVisualWrapper data-test={id}>
      <BlobSvg
        width="656"
        height="483"
        viewBox="0 0 656 483"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.032 263.178C-12.7076 306.036 1.9262 360.811 44.7175 385.521C64.2865 396.821 86.3552 399.877 106.911 395.767C135.964 389.958 174.478 392.286 195.41 413.237C287.026 504.932 434.386 506.111 524.549 415.869C557.926 382.463 578.786 341.211 587.175 297.849C593.188 266.763 622.135 240.038 641.727 215.164C643.636 212.74 645.396 210.15 646.983 207.399C664.841 176.464 654.278 136.927 623.391 119.091C615.116 114.313 606.221 111.577 597.29 110.727C571.419 108.264 540.286 104.827 521.919 86.4433C459.425 23.8952 370.994 3.46456 292.471 25.4381C271.369 31.3433 248.461 27.5277 229.096 17.304C219.927 12.4631 210.096 8.50778 199.688 5.58525C122.346 -16.1326 42.4409 27.405 21.2158 102.829C13.2978 130.967 14.6646 159.567 23.5063 185.496C31.8627 210.002 24.986 240.737 12.032 263.178Z"
          fill="#EFDBB2"
        />
      </BlobSvg>
      {productImage?.url && (
        <ProductWrapper data-test={`${id}-product`}>
          <PictureAsset
            sources={ImagesService.getPictureSources(productImage)}
            alt={productImage?.altText || ''}
          />
        </ProductWrapper>
      )}
    </ProductVisualWrapper>
  </ProductVisual>
);
